<template>
  <div class="template">
    <!-- 标题 -->
    <!-- 模板设置 front-template-V1-templateSetting-->
    <div class="setting-title">
      {{ $t("front-template-V1-templateSetting") }}
    </div>
    <!-- 解释 -->
    <!-- 点击”新建”，创建新的模板。启用后即可使用模板。 front-template-V1-Click-new-template-->
    <div class="intro">
      {{ $t("front-template-V1-Click-new-template") }}
    </div>
    <!-- 列表 -->
    <div class="list">
      <div class="firstIntro">
        <div class="title" style="font-size: 16px">
          <el-select v-model="chioceObject" placeholder="" filterable>
            <el-option
              v-for="item in objectTemplateLIst"
              @click.native="changeObjTemplate(item.id)"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 按钮 -->
        <!-- 新建 label.import.page1.newcreate-->
        <button class="create" @click="createNewTemplate">
          {{ $t("label.import.page1.newcreate") }}
        </button>
        <div></div>
      </div>
      <!-- 数据 -->
      <div class="header">
        <el-table
          :empty-text="$t('label.weixin.nomore')"
          ref="multipleTable"
          max-height="400"
          :data="templateList"
          @sort-change="sortChange"
          :header-cell-style="{ background: '#F0F0F0', color: '#333333' }"
        >
          <el-table-column
            :prop="item.name"
            v-for="(item, index) in headList"
            :key="index"
            :label="item.fieldLabel"
            :sortable="
              item.isshow && templateList.length != 0 ? 'custom' : false
            "
            show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <!-- 编辑 label.emailtocloudcc.edit-->
              <!-- 删除 label.emailobject.delete-->
              <!-- 禁用 label.emailsync.status.disabel-->
              <!-- 启用 label.emailtocloudcc.button.enable-->
              <div class="opreator" v-if="item.id == '001'">
                <span @click="editTemplate(scope.row)">{{
                  $t("label.emailtocloudcc.edit")
                }}</span>
                <span v-if="scope.row.isstd != '1'">|</span>
                <span
                  class="delete"
                  v-if="scope.row.isstd != '1'"
                  @click="DeleteConfirmation(scope.row)"
                  >{{ $t("label.emailobject.delete") }}</span
                >
                <span>|</span>
                <span
                  v-if="scope.row.isenable == '1'"
                  @click="updataTemplate(scope.row, 'disabel')"
                  >{{ $t("label.emailsync.status.disabel") }}</span
                >
                <span v-else @click="updataTemplate(scope.row, 'enable')">{{
                  $t("label.emailtocloudcc.button.enable")
                }}</span>
              </div>
              <div
                v-show="item.id == '003'"
                @mouseover="changePenHide(scope.$index)"
                @mouseleave="changePenDisplay(scope.$index)"
              >
                <!-- 只读名称 -->
                <div class="tem-name" v-if="!scope.row.isEdit">
                  <span>{{ scope.row.templatename }}</span>
                  <div
                    v-show="scope.row.isHide"
                    @click="editTempName(scope.row.templatename, scope.$index)"
                  >
                    <svg class="icon editTemName" aria-hidden="true">
                      <use href="#icon-edit-big"></use>
                    </svg>
                  </div>
                </div>
                <!-- 修改名称 -->
                <div class="edi-name" v-if="scope.row.isEdit">
                  <el-input
                    v-model="editName"
                    placeholder=""
                    :autofocus="true"
                    @blur="saveTemName(scope.row.templateid, scope.$index)"
                    v-focus
                  ></el-input>
                </div>
              </div>
              <div v-if="item.id == '006'" class="isenable">
                <el-checkbox
                  v-model="isEnableStatus"
                  :disabled="true"
                  v-if="scope.row.isenable == '1'||scope.row.isenable == 'true'"
                ></el-checkbox>
                <el-checkbox
                  v-model="DisableStatus"
                  :disabled="true"
                  v-if="scope.row.isenable == '0'||scope.row.isenable == 'false'"
                ></el-checkbox>
              </div>
              <div v-else>{{ scope.row[item.name] }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 提示 UG.ST.001-->
    <!-- 确定删除”{{currentTelmplateName}}“模板吗 front-template-V1-DeleteTempalte-->
    <el-dialog
      :title="$t('UG.ST.001')"
      :visible.sync="deleteStatus"
      width="30%"
    >
      <span>{{
        $t("front-template-V1-DeleteTempalte", {
          currentTelmplateName: currentTelmplateName,
        })
      }}</span>
      <span slot="footer" class="dialog-footer">
        <!-- 取消 label.emailsync.button.cancel-->
        <!-- 确定 label.tabpage.ok-->
        <el-button @click="deleteStatus = false">{{
          $t("label.emailsync.button.cancel")
        }}</el-button>
        <el-button type="primary" @click="deleteTemplate">{{
          $t("label.tabpage.ok")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>




<script>
import * as objectTemplate from "./api";
export default {
  data() {
    return {
      //模板名称回显值
      editName: "",
      isEnableStatus: true,
      DisableStatus: false,
      templateList: [],
      //对象模板列表
      objectTemplateLIst: [],
      //当前选择对象
      chioceObject: "",
      currentTelmplateName: "",
      currentTelmplateId: "",
      deleteStatus: false,
      headList: [
        // 操作 label.import.index.operation
        // 名称 label.emailtocloudcc.name
        // 适用对象 front-objectTemplate-V1-UseObject
        // 修改时间 front-template-V1-Modification-time
        // 创建时间 label.createdate
        // 启用|禁用 front-template-V1-Enable-disable
        {
          id: "001",
          fieldLabel: this.$i18n.t("label.import.index.operation"),
          name: "opreator",
          isshow: false,
        },
        {
          id: "002",
          fieldLabel: this.$i18n.t("front-objectTemplate-V1-UseObject"),
          name: "objname",
          isshow: true,
          isAsc: false,
          fieldapi: "objid",
        },
        {
          id: "003",
          fieldLabel: this.$i18n.t("label.emailtocloudcc.name"),
          name: "name",
          isshow: true,
          isAsc: false,
          fieldapi: "name",
        },
        {
          id: "007",
          fieldLabel: this.$i18n.t("label.appupload.component.type.recordtype"),
          name: "recordtypeccname",
          isshow: true,
          isAsc: false,
          fieldapi: "recordtypeccname",
        },
        {
          id: "004",
          fieldLabel: this.$i18n.t("front-template-V1-Modification-time"),
          name: "lastmodifydate",
          isshow: true,
          isAsc: false,
          fieldapi: "lastmodifydate",
        },
        {
          id: "005",
          fieldLabel: this.$i18n.t("label.createdate"),
          name: "createdate",
          isshow: true,
          isAsc: false,
          fieldapi: "createdate",
        },
        {
          id: "006",
          fieldLabel: this.$i18n.t("front-template-V1-Enable-disable"),
          name: "isenable",
          isshow: true,
          isAsc: false,
          fieldapi: "isenable",
        },
      ],
    };
  },
  //input自动获取焦点指令
  directives: {
    focus: {
      // 指令的定义
      inserted: function (el) {
        // 聚焦元素
        el.querySelector("input").focus();
      },
    },
  },
  methods: {
    //表格排序事件
    sortChange(column) {
      let sortBy = column.prop == "objname" ? "objid" : column.prop;
      if (column.order == "descending") {
        this.changeObjTemplate(this.chioceObject, "desc", sortBy);
      } else {
        this.changeObjTemplate(this.chioceObject, "asc", sortBy);
      }
    },
    //保存编辑后模板名称
    async saveTemName(id, index) {
      let resultData = await objectTemplate.updateTemplateName({
        id: id,
        name: this.editName,
      });
      if (resultData.result) {
        this.templateList[index].isEdit = false;
        this.templateList[index].templatename = this.editName;
      }
    },
    //编辑模板名称
    editTempName(name, index) {
      this.templateList[index].isEdit = true;
      this.editName = name;
    },
    //鼠标移入小笔
    changePenHide(index) {
      this.templateList[index].isHide = true;
    },
    //鼠标移出小笔
    changePenDisplay(index) {
      this.templateList[index].isHide = false;
    },
    //切换对象更换对象对应模板列表
    async changeObjTemplate(id, orderBy, sortBy) {
      let obj = {};
      if (id && id != "allTemplate") {
        obj.objId = id;
      }
      if (orderBy) {
        obj.orderBy = orderBy;
      }
      if (sortBy) {
        obj.sortBy = sortBy;
      }
      let resultData = await objectTemplate.getObjTemplateList(obj);
      resultData.data.forEach((item) => {
        item.isHide = false;
        item.isEdit = false;
        item.isenable = item.isenable == "1"||item.isenable == "true" ? true : false;
      });
      this.templateList = resultData.data;
    },
    //获取可创建模板的对象列表
    async getAllObjList() {
      let resultData = await objectTemplate.getCanCreatedObjList({});
      if (resultData.result) {
        this.objectTemplateLIst = resultData.data;
        //  全部模板
        this.objectTemplateLIst.unshift({
          id: "allTemplate",
          name: this.$i18n.t("vue_label_SMS_alltemplates"),
        });
      }
    },
    //获取单个服务报告模板详情
    editTemplate(val) {
      this.$router.push({
        path: "/systemSettings/setuppage/TemplateDetail",
        query: {
          Tid: val.templateid,
          Tsubid: val.subid,
          TobjId: val.objid,
          Tname: val.templatename,
          Type: val.type,
        },
      });
    },
    //跳转创建模板
    createNewTemplate() {
      this.$router.push({
        path: "/systemSettings/setuppage/newObjectTemplate",
      });
    },
    //更新启禁用状态
    async updataTemplate(data, operation) {
      let status;
      let statusmsg;
      // 启用成功 label.group.isusing.success
      // 禁用成功 lable.Disabled.successfully
      // 禁用时判断当前对象和记录类型下是否有可禁用的数据，每个对象下至少保留一条启用的数据
      if (operation == "disabel") {
        let enabledData = [];
        // 当前模板包含记录类型判断：//记录类型，对象名，启用状态
        // 不包含记录类型：对象名，启用状态
        if (data.recordtypeccname) {
          enabledData = this.templateList.filter((item) => {
            return (
              data.recordtypeccname == item.recordtypeccname &&
              data.objname == item.objname &&
              (item.isenable === "1" || item.isenable === true)
            );
          });
        } else {
          enabledData = this.templateList.filter((item) => {
            return (
              data.objname == item.objname &&
              (item.isenable === "1" || item.isenable === true)
            );
          });
        }
        // 同一对象下启用数据小于等于1
        if (!enabledData || (enabledData && enabledData.length <= 1)) {
          this.$message.error(this.$i18n.t("c1048")); //请至少启用一个模板
          return;
        }
      }
      if (data.isenable == "0"||data.isenable == "false") {
        status = "1";
        statusmsg = this.$i18n.t("label.group.isusing.success");
      }
      if (data.isenable == "1"||data.isenable == "true") {
        status = "0";
        statusmsg = this.$i18n.t("lable.Disabled.successfully");
      }
      let resultData = await objectTemplate.updateTemplateStatus({
        id: data.templateid,
        isEnable: status,
      });
      if (resultData.result) {
        this.templateList.map((item) => {
          if (item.templateid == data.templateid) {
            item.isenable = status;
          }
        });
        this.$message({
          message: statusmsg,
          type: "success",
        });
      }
    },
    //删除确认弹窗
    DeleteConfirmation(data) {
      this.currentTelmplateName = data.templatename;
      this.currentTelmplateId = data.templateid;
      this.deleteStatus = true;
    },
    //删除模板
    async deleteTemplate() {
      let resultData = await objectTemplate.deleteTemplate({
        id: this.currentTelmplateId,
      });
      if (resultData.result) {
        this.templateList.forEach((item, index) => {
          if (item.templateid == this.currentTelmplateId) {
            this.templateList.splice(index, 1);
            this.deleteStatus = false;
          }
        });
        // “{{currentTelmplateName}}”模板删除成功 front-template-V1-Delete-succeeded
        this.$message({
          message: this.$i18n.t("front-template-V1-Delete-succeeded", {
            currentTelmplateName: this.currentTelmplateName,
          }),
          type: "success",
        });
      }
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.changeObjTemplate(this.$route.query.id);
      this.chioceObject = this.$route.query.id;
    } else {
      this.changeObjTemplate();
      this.chioceObject = "allTemplate";
    }
    this.getAllObjList();
  },
  created() {},
  components: {},
};
</script>


<style lang="scss" scoped>
* {
  margin: 0;
}
.Table-header {
  i {
    cursor: pointer;
  }
}
.edi-name {
  ::v-deep .el-input__inner {
    height: 34px;
    line-height: 34px;
  }
}
.editTemName {
  cursor: pointer;
}
.template {
  padding-top: 18px;
  padding-left: 10px;
  padding-right: 10px;
}
.setting-title {
  font-size: 20px;
  color: #333333;
  font-weight: bold;
}
.title {
  ::v-deep .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
  ::v-deep .el-input__icon {
    line-height: 30px;
  }
}
.tem-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.intro {
  margin-top: 20px;
  height: 19px;
  font-size: 14px;
  color: #333333;
  line-height: 19px;
}
.list {
  margin-top: 20px;
  width: 100%;
  background: #f5f5f5;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
  padding-top: 18px;
  padding-left: 10px;
  padding-right: 10px;
}
.firstIntro {
  display: flex;
  justify-content: space-between;
  ::v-deep .el-icon-arrow-up:before {
    content: "\e778";
  }
  ::v-deep .el-select .el-input .el-select__caret {
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
  }
}
.create {
  padding: 3px 16px;
  height: 30px;
  font-size: 14px;
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
}
.create:focus {
  outline: none;
}
.header {
  width: 100%;
  margin-top: 20px;
  padding-bottom: 10px;
}
::v-deep .el-table .cell div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.isenable {
  cursor: pointer;
  color: #006dcc;
  font-size: 14px;
}
.opreator {
  cursor: pointer;
  color: #006dcc;
  font-size: 14px;
}
.opreator span {
  margin-left: 3px;
}
.delete {
  cursor: pointer;
}
// .delete::after {
//   content: "|";
//   color: #006dcc;
// }
</style>
