import axios from '@/config/httpConfig'

//获取对象字段列表
export function getFieldList(params) {
  return axios.post('reporttemplate/getFieldList', params)
}


//根据对象获取对象对应的模板列表
export function getObjTemplateList(params) {
  return axios.post('/objTemplate/getObjTemplateList', params)
}

//列表页更新模板名称（区别于编辑模板）
export function updateTemplateName(params) {
  return axios.post('/objTemplate/updateTemplateName', params)
}

//列表页更新模板启用状态
export function updateTemplateStatus(params) {
  return axios.post('/objTemplate/updateTemplateStatus', params)
}

//列表页删除模板
export function deleteTemplate(params) {
  return axios.post('/objTemplate/deleteTemplate', params)
}

//新建模板
export function cteateTemplate(params) {
  return axios.post('/objTemplate/createTemplate', params)
}

//根据模板id获取模板详细信息
export function getTemplateById(params) {
  return axios.post('/objTemplate/getTemplateById', params)
}

//获取所选择适用对象的【查找字段关联对象+用户+组织】
export function getCheckedRelatedObj(params) {
  return axios.post('/objTemplate/getCheckedRelatedObj', params)
}


//上传图片
export function upload(params) {
  return axios.post('/file/upload', params)
}
//获取可创建模板的对象列表
export function getCanCreatedObjList(params) {
  return axios.post('/objTemplate/getCanCreatedObjList', params)
}
// 获获取选项列表值
export function GetViewGetSelectValue(data) {
  return axios.post('/view/getSelectValue', data)
}
// 获取币种信息
export function GetCurrencyInfo(data) {
  return axios.post('/currency/getCurrencyInfo', data)
}
//保存编辑后的模板
export function saveObjTemplate(params) {
  return axios.post('/objTemplate/saveTemplate', params)
}

// 预览模板接口（同预览生成PDF文件接口）
export function viewObjTemplate(params) {
  return axios.post('/cpq/template/getTemplatePdf', params)
}

//获取对象字段
export function getRelationList(params) {
  return axios.post('/objTemplate/getRelationList', params)
}

// 获取查找信息
export function getLookupInfo(data) {
  return axios.post('/lookup/getLookupInfo', data)
}

// 根据名称搜索对象记录接口--查找字段自动搜索
export function queryMoreDataByName(data) {
  return axios.post('objectdetail/queryMoreDataByName', data)
}

// 获取记录类型
export function getRecordType(data) {
  return axios.post('/batch/getRecordType', data)
}